import React from 'react';
import Helmet from '../../Helmet';
import { Footer, Main, Projects, Layout } from '../components';
import ConfigurationProvider from '../providers/ConfigurationProvider';
import { aboutMe } from '../utils/me';

export default () => (
  <ConfigurationProvider>
    <Helmet
      title='Welcome'
      meta={[{ name: 'keywords', content: aboutMe.keywords.join(', ') }]}
    />
    <Layout>
      <Main />
      <Projects />
      <Footer />
    </Layout>
  </ConfigurationProvider>
);
